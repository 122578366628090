<template>
  <admin>
    <metatag title="Test Drive Units"></metatag>

    <page-header>
      <template v-slot:action>
        <can :code="['admin']">
          <v-btn
            :block="$vuetify.breakpoint.smAndDown"
            large
            color="info"
            exact
            :to="{ name: 'test-drives-units-upload' }"
          >
            <v-icon small left>mdi-cloud-upload</v-icon>
            <span v-text="'Upload'"></span>
          </v-btn>
        </can>
      </template>
    </page-header>

    <v-row v-if="userDetails">
      <v-col>
        <v-card>
          <can :code="['admin']">
            <toolbar-menu
              :filter-model="true"
              :filter-dealer="true"
              :items.sync="tabletoolbar"
              @update:search="setSearch"
              @update:model="setModelFilter"
              @update:dealer="setDealerFilter"
            ></toolbar-menu>
          </can>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="available_units.headers"
              :items="items"
              :loading="loading"
              :items-per-page="5"
              :server-items-length="10"
              :options.sync="options"
              color="primary"
              item-key="id"
              class="text-no-wrap"
            >
              <template v-slot:progress><span></span></template>

              <template v-slot:item.quantity="{ item }">
                <strong>{{ item.quantity }}</strong>
              </template>

              <template v-slot:item.updated_at="{ item }">  
                <small>{{ formatDate(item.updated_at, "YYYY-MM-DD H:m:s") }}</small> <br />
              </template>

              <template v-slot:loading>
                <v-slide-y-transition mode="out-in">
                  <skeleton-avatar-table></skeleton-avatar-table>
                </v-slide-y-transition>
              </template>
              <template v-slot:item.vehicle_desc="{ item }">
                <small>{{ item.model_name }}</small> <br />
                <small>{{ item.variant_name }}</small> <br />
                <small>{{ item.model_year }}</small>
              </template>
              <template v-slot:item.editAction="{ item }">
                <div class="text-no-wrap">
                  <!-- Edit -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="editItem(item)" text icon v-on="on">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span v-text="'Edit'"></span>
                  </v-tooltip>
                  <!-- Edit -->
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <template v-if="uploadDialog">
      <v-dialog v-model="uploadDialog" max-width="1200px" class="overflow-hidden">
        <v-card class="pa-4">
          <v-card-title>
            <h2 title="Edit" class="mb-1">Upload</h2>
          </v-card-title>
          <v-card-text class="overflow-y-auto">
            <v-btn @click="onPickFile" block large color="info" exact>
              <v-icon small left>mdi-cloud-upload</v-icon>
              <span v-text="'Import Excel'"></span>
            </v-btn>
            <input
              type="file"
              style="display: none"
              ref="fileInput"
              accept=".csv, 
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
            application/vnd.ms-excel"
              @change="onFilePicked"
            />
          </v-card-text>
          <v-card-text class="overflow-y-auto text-center">
            {{ file.name }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              color="primary"
              exact
              class="ma-1 white--text px-5"
              @click="uploadTestDriveUnit"
            >
              Upload
            </v-btn>
            <v-btn
              large
              color="grey"
              exact
              class="ma-1 white--text px-5"
              @click="openUploadDialog(false)"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <v-dialog v-model="editActionDialog" max-width="600px" class="overflow-hidden">
      <v-card class="pa-4">
        <v-card-title>
          <h2 title="Edit" class="mb-1">Edit #{{ selectedItem.id }}</h2>
        </v-card-title>
        <v-card-text class="overflow-y-auto">
          <v-form ref="editForm" v-model="validEdit" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Inventory"
                  outlined
                  hide-details
                  background-color="selects"
                  v-model="selectedItem.quantity"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large color="grey" exact class="ma-1 white--text px-5" @click="closeEdit">
            Cancel
          </v-btn>

          <v-btn
            large
            exact
            color="green darken-1"
            class="ma-1 white--text px-5"
            :loading="loadingEditSave"
            :disabled="loadingEditSave"
            @click="updateTestDriveUnits"
          >
            <v-icon left>mdi-content-save</v-icon>
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </admin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "lodash";
import * as helpers from "@/core/helpers";

export default {
  data() {
    return {
      userList: [
        {
          text: "HCPI",
          value: "hcpi",
        },
        {
          text: "Dealer",
          value: "dealer",
        },
      ],
      available_units: {
        loading: true,
        headers: [
          { text: "Vehicle Model", align: "center", value: "model_name", class: "text-no-wrap" },
          {
            text: "Vehicle Description",
            align: "center",
            value: "vehicle_desc",
            class: "text-no-wrap",
          },
          {
            text: "Color Description",
            align: "center",
            value: "color_name",
            class: "text-no-wrap",
          },
          { text: "Dealer Name", align: "center", value: "dealer_code", class: "text-no-wrap" },
          { text: "Inventory", align: "center", value: "quantity", class: "text-no-wrap" },
          {
            text: "Date Updated",
            align: "start",
            sortable: true,
            value: "updated_at",
          },
          {
            text: "Actions",
            align: "center",
            value: "editAction",
            class: "muted--text text-no-wrap",
          },
        ],
        data: [],
      },
      items: [],
      loading: true,
      total: 0,
      options: {},
      tabletoolbar: {
        isSearching: false,
        search: null,
        status: [],
        dealers: [],
        model: [],
        type: "testdrive",
      },
      filters: {
        model: null,
        dealer: null,
        q: "",
      },
      filters: {
        model: null,
        dealer: null,
        q: "",
      },
      userDetails: {},
      uploadDialog: false,
      file: {},
      selectedItem: {},
      editActionDialog: false,
      loadingEditSave: false,
      validEdit: true,
    };
  },
  computed: {
    ...mapGetters({
      data: "testDrives/GET_TEST_DRIVE_UNITS",
      models: "testDrives/GET_MODELS",
      dealers: "testDrives/GET_DEALERS"
    }),
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        this.getItems(page, itemsPerPage, this.filters);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getAvailableUnits: "testDrives/getTestDriveUnits",
      getDealers: "testDrives/getDealers",
      importTestDriveUnit: "testDrives/importTestDriveUnit",
      updateTestDriveUnitQuantity: "testDrives/updateTestDriveUnitQuantity",
    }),
    showTip(e) {
      document.querySelector(".tooltip-message").classList.remove("d-none");
    },
    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.filters.q = e.target.value;
      this.getItems(1, itemsPerPage, this.filters);
    }, 300),

    formatDate(item, format) {
      return helpers.format_date(new Date(item), format);
    },

    getItems(page, itemsPerPage, filters = {}) {
      this.getAvailableUnits({
        page: page,
        per_page: itemsPerPage,
        filters: filters,
      }).then((data) => {
        this.items = this.data;
        this.total = this.data.length;
        this.loading = false;
        this.tabletoolbar.isSearching = false;
      });
    },

    getTableToolbar() {
      this.getDealers().then(() => {
        for (var i = 0; i < this.dealers.length; i++) {
          this.tabletoolbar.dealers.push(this.dealers[i].name);
        }
      });
    },

    setModelFilter: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.filters.model = e;
      this.getItems(1, itemsPerPage, this.filters);
    }, 300),

    setDealerFilter(e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.filters.dealer = e;
      this.getItems(1, itemsPerPage, this.filters);
    },
    openUploadDialog(val) {
      this.uploadDialog = val;
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      this.file = files[0];
    },
    uploadTestDriveUnit() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var formData = new FormData();

      formData.append("file", this.file);

      this.importTestDriveUnit(formData).then((data) => {
        if (data.success === true) {
          this.getItems(page, itemsPerPage, this.filters);
          this.file = {};
        }
      });
    },
    editItem(item) {
      this.selectedItem = item;
      this.editActionDialog = true;
    },
    closeEdit() {
      this.selectedItem = {};
      this.editActionDialog = false;
    },
    async updateTestDriveUnits() {
      const isValid = this.$refs.editForm.validate();
      if (!isValid) {
        return;
      }
      this.loadingEditSave = true;
      await this.updateTestDriveUnitQuantity(this.selectedItem);
      this.loadingEditSave = false;
      this.closeEdit();
      this.getItems(1, 5, this.filters);
    },
  },
  created() {
    if (!localStorage.user) {
      this.$router.push({ name: "login" });
    } else {
      this.userDetails = JSON.parse(localStorage.user);
      this.getTableToolbar();
    }
  },
};
</script>

<style></style>
